import React from 'react';

import { Head } from '../../components/head';
import { Checkbox, Input, Select, Textarea, useForm } from '../../hooks/form';
import { useNotification } from '../../hooks/notification/Notification';
import { useRouter } from '../../hooks/router';
import Api from '../../services/Api';

export default function ClasseCreate() {
  const router = useRouter();
  const notification = useNotification();
  const form = useForm({
    name: '',
    number: '',
    description: '',
    department: null,
    type: 'public',
    firstClasseAt: null,
    lastClasseAt: null,
    registrationOpenStartAt: new Date(),
    registrationOpenEndAt: new Date(),
    replayStartAt: new Date(),
  }, (data) => {
    if (data.name === null || data.name.trim().length === 0) {
      return alert('Veuillez mettre un nom !');
    }
    if (data.firstClasseAt === null || data.lastClasseAt === null) {
      return alert('Veuillez mettre une date de début et de fin de cours !');
    }
    if (data.department === null) {
      return alert('Veuillez mettre un département programmateur !');
    }
    Api.post('/classes', data)
      .then(response => {
        notification.notify('Le cours a bien été créé !');
        router.goTo('classe', { id: response.data.id });
      }).catch(() => {
        // @todo error when email is used
        alert('Une erreur est survenue');
      })
  });

  return (
    <div className="ressource-page">
      <Head noTabs title="Nouveau cours">
        <button className="btn" onClick={form.onSubmit}>Enregistrer</button>
      </Head>
      <form style={{ padding: '20px', display: 'flex', width: '100%', gap: '20px' }}>
        <div style={{ flex: 1 }}>
          <Input label="Nom du cours *" {...form.field('name')} />
          <br />
          <Input label="Sous titre du cours" {...form.field('subName')} />
          <br/>
          <Select label="Département programmateur *" noSearch {...form.field('department')}>
            <option value="cp">Cours Publics</option>
            <option value="fnd">FND</option>
            <option value="issr">ISSR</option>
            <option value="fr">FEB</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fcf">FCF</option>
            <option value="ihefr">IHEFR</option>
            <option value="e-learning">ELEARNING</option>
            <option value="other">Autre</option>
            </Select>
          <br />
          <Input label="Numéro du cours" {...form.field('number')} />
          <br />
          <Input label="Code produit" {...form.field('productCode')} />
          <br/>
          <Textarea label="Description du cours" {...form.field('description')} />
          <br/>
          <Checkbox label="MOOC" {...form.field('mooc', { type: 'checkbox' })} />
          <br/>
          <Checkbox label="Replay" {...form.field('replay', { type: 'checkbox' })} />
          <br />
          { form.data.replay === true &&
            <>
              <Input label="Date début du replay" {...form.field('replayStartAt', { type: 'date' })} />
              <br/>
            </>
          }
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Input label="Date du premier cours *" {...form.field('firstClasseAt', { type: 'date' })} />
            </div>
            <div style={{ flex: 1, marginLeft: '20px' }}>
              <Input label="Date du dernier cours *" {...form.field('lastClasseAt', { type: 'date' })} />
            </div>
          </div>
          <br/>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Select label="Période" noSearch {...form.field('period')}>
                <option value={null}>Non définie</option>
                <option value="annual">Annuel</option>
                <option value="first-semester">1er semestre</option>
                <option value="second-semester">2nd semestre</option>
              </Select>
            </div>
            <div style={{ flex: 1, marginLeft: '20px' }}>
              <Select label="Année" noSearch {...form.field('year')}>
                <option value={null}>Non définie</option>
                <option value={2021}>2021 - 2022</option>
                <option value={2022}>2022 - 2023</option>
                <option value={2023}>2023 - 2024</option>
                <option value={2024}>2024 - 2025</option>
                <option value={2025}>2025 - 2026</option>
                <option value={2026}>2026 - 2027</option>
                <option value={2027}>2027 - 2028</option>
                <option value={2028}>2028 - 2029</option>
                <option value={2029}>2029 - 2030</option>
                <option value={2030}>2030 - 2031</option>
                <option value={2031}>2031 - 2032</option>
              </Select>
            </div>
          </div>
          <br />
          <Input label="Limite d'étudiant" {...form.field('capacity')} />
          <br />
          <Input suffix="€ TTC" label="Tarif Institutions" {...form.field('fullPriceTtc', { type: 'float' })} />
          <br/>
          <Input suffix="€ TTC" label="Tarif individuel" {...form.field('individualPriceTtc', { type: 'float' })} />
          <br/>
          <Input suffix="€ TTC" label="Tarif jeune - 30 ans" {...form.field('youthPriceTtc', { type: 'float' })} />
          <br />
          <Checkbox label="Inscriptions ouvertes" {...form.field('registrationOpen', { type: 'checkbox' })} />
          <br/>
          { form.data.registrationOpen === true &&
            <>
              <Input label="Date début d'inscription" {...form.field('registrationOpenStartAt', { type: 'date' })} />
              <br/>
              <Input label="Date limite d'inscription" {...form.field('registrationOpenEndAt', { type: 'date' })} />
              <br/>
            </>
          }
        </div>
      </form>
    </div>
  );
}
