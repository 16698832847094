import React from 'react';
import moment from 'moment';

import { Field } from '../../../components/field';
import { Head } from '../../../components/head';
import { Input, Select, Textarea, useForm } from '../../../hooks/form';
import { List, useList } from '../../../hooks/list';
import { useModal } from '../../../hooks/modal/Modal';
import { Link } from '../../../hooks/router';
import Api from '../../../services/Api';
import { useNotification } from '../../../hooks/notification/Notification';

function SelectClasseModal({ onSelected, excludeIds }) {
  const modal = useModal();
  const { filters, items, isLoading, load } = useList('classes', {
    search: '',
    year: null,
    period: null,
    excludeIds,
  });

  function select(classe) {
    onSelected(classe);
    modal.close();
  }

  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Choisissez un cours" />
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('year')}>
            <option value={null}>Toutes années</option>
            <option value={2021}>2021 - 2022</option>
            <option value={2022}>2022 - 2023</option>
            <option value={2023}>2023 - 2024</option>
            <option value={2024}>2024 - 2025</option>
            <option value={2025}>2025 - 2026</option>
            <option value={2026}>2026 - 2027</option>
            <option value={2027}>2027 - 2028</option>
            <option value={2028}>2028 - 2029</option>
            <option value={2029}>2029 - 2030</option>
            <option value={2030}>2030 - 2031</option>
            <option value={2031}>2031 - 2032</option>
          </Select>
        </div>
        <div className="filter" style={{ minWidth: '120px' }}>
          <Select noSearch {...filters.field('period')}>
            <option value={null}>Toutes périodes</option>
            <option value="annual">Annuel</option>
            <option value="first-semester">1er semestre</option>
            <option value="second-semester">2nd semestre</option>
          </Select>
        </div>
      </form>
      <br/>
      <List headers={[{ name: 'Cours', flex: 3 }, { name: 'Code produit' }, { name: 'Programmateur' }]} onLoadMore={load} isLoading={isLoading}>
        { items.map(classe => (
          <div onClick={() => select(classe)} key={classe.id} className="item" to="classe" params={{ id: classe.id }}>
            <div className="prop" style={{ flex: 3, whiteSpace: 'normal' }}>
                {classe.name}
            </div>
            <div className="prop">
              {classe.productCode}
            </div>
            <div className="prop">
              {classe.department ? { 'cp': 'Cours Publics', 'fnd': 'FND', 'issr': 'ISSR', 'fr': 'FEB', 'ccdej': 'CCDEJ', 'fcf': 'FCF', 'ihefr': 'IHEFR', 'e-learning': 'ELEARNING', 'other': 'Autre' }[classe.department] : ''}
            </div>
          </div>
        ))}
      </List>
    </div>
  );
}

function UpdateInformations({ classePackage, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(classePackage, (data) => {
    Api.post(`/packages/${classePackage.id}/informations`, data)
      .then(response => {
        onUpdated(response.data);
        notification.notify('Les informations ont été mises à jour !');
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les informations" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input label="Titre du cours" {...form.field('name')} />
        <br/>
        <Textarea label="Description" {...form.field('description')} />
        <br />
        <Input label="Numéro du package" {...form.field('number')} />
        <br />
        <Input label="Code produit" {...form.field('productCode')} />
        <br/>
        <Select noSearch {...form.field('program')}>
          <option value={null}>Choisir un département</option>
          <option value="fnd">FND</option>
          <option value="issr">ISSR</option>
          <option value="ccdej">CCDEJ</option>
          <option value="fr">FEB</option>
          <option value="other">Autre</option>
        </Select>
        <br />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Input label="Date du premier cours" {...form.field('firstClasseAt', { type: 'date' })} />
          </div>
          <div style={{ flex: 1, marginLeft: '20px' }}>
            <Input label="Date du dernier cours" {...form.field('lastClasseAt', { type: 'date' })} />
          </div>
        </div>
        <br/>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Select label="Période" noSearch {...form.field('period')}>
              <option value={null}>Non définie</option>
              <option value="annual">Annuel</option>
              <option value="first-semester">1er semestre</option>
              <option value="second-semester">2nd semestre</option>
            </Select>
          </div>
          <div style={{ flex: 1, marginLeft: '20px' }}>
            <Select label="Année" noSearch {...form.field('year')}>
              <option value={null}>Non définie</option>
              <option value={2021}>2021 - 2022</option>
              <option value={2022}>2022 - 2023</option>
              <option value={2023}>2023 - 2024</option>
              <option value={2024}>2024 - 2025</option>
              <option value={2025}>2025 - 2026</option>
              <option value={2026}>2026 - 2027</option>
              <option value={2027}>2027 - 2028</option>
              <option value={2028}>2028 - 2029</option>
              <option value={2029}>2029 - 2030</option>
              <option value={2030}>2030 - 2031</option>
              <option value={2031}>2031 - 2032</option>
            </Select>
          </div>
        </div>
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

function UpdatePrices({ classePackage, onUpdated }) {
  const notification = useNotification();
  const modal = useModal();
  const form = useForm(classePackage, (data) => {
    Api.post(`/packages/${classePackage.id}/prices`, data)
      .then(response => {
        onUpdated(response.data);
        notification.notify('Les prix ont été mis à jour !');
        modal.close();
      })
      .catch(() => {
        alert('Une erreur est survenue');
      });
  });

  return (
    <div className="ressource-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Modifier les tarifs" />
      <form onSubmit={form.onSubmit} style={{ padding: '20px' }}>
        <Input suffix="€ TTC" label="Tarif Institutions" {...form.field('fullPriceTtc')} />
        <br/>
        <Input suffix="€ TTC" label="Tarif individuel" {...form.field('individualPriceTtc')} />
        <br/>
        <Input suffix="€ TTC" label="Tarif jeune - 30 ans" {...form.field('youthPriceTtc')} />
        <br/>
        <button className="btn full">Enregistrer</button>
      </form>
    </div>
  );
}

export default function PackageRessourceOverview({ classePackage, setClassePackage }) {
  const modal = useModal();
  const notification = useNotification();

  function onClasseSelected(classe) {
    Api.post(`/packages/${classePackage.id}/classes`, classe)
      .then(response => {
        setClassePackage(response.data);
        notification.notify('Le cours a été ajouté !');
      }).catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  }

  function onClasseRemove(classe) {
    Api.del(`/packages/${classePackage.id}/classes/${classe.id}`)
      .then(response => {
        setClassePackage(response.data);
        notification.notify('Le cours a été retiré !');
      }).catch(error => {
        console.log(error);
        alert('Une erreur est survenue');
      });
  }

  function onUpdated(classe) {
    setClassePackage(classe);
  }

  return (
    <div className="ressource-page" style={{ padding: '20px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
          <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Informations</span><small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdateInformations classePackage={classePackage} onUpdated={classePackage => onUpdated(classePackage)} />)}>Modifier</small></h3>
          <Field label="Nom">
              {classePackage.name}
          </Field>
          <Field label="Description" box>
            <div style={{ borderRadius: '8px', background: '#E9E9E9', padding: '10px', fontFamily: 'Courier New' }}>
              {classePackage.description || <span style={{ color: 'grey' }}>Aucune</span>}
            </div>
          </Field>
          <Field label="Numéro du package">
            {classePackage?.number || 'n/a'}
          </Field>
          <Field label="Code produit">
            {classePackage?.productCode || 'n/a'}
          </Field>
          <Field label="Département">
            {classePackage.program ? classePackage.program.toUpperCase() : 'n/a'}
          </Field>
          <Field label="Date du premier cours">
            { classePackage.firstClasseAt ? (
              <> Le premier cours aura lieu le {moment(classePackage.firstClasseAt).format('DD/MM/YYYY')}</>
            ) : (
              <>n/a</>
            )}
          </Field>
          <Field label="Date du dernier cours">
            { classePackage.lastClasseAt ? (
              <> Le dernier cours aura lieu le {moment(classePackage.lastClasseAt).format('DD/MM/YYYY')}</>
            ) : (
              <>n/a</>
            )}
          </Field>
          <Field label="Période">
            {classePackage.period ? { annual: 'Annuel', 'first-semester': '1er semestre', 'second-semester':'2nd semestre'}[classePackage.period] : 'n/a'}
          </Field>
          <Field label="Année">
            {classePackage.year ? `${classePackage.year} - ${classePackage.year+1}` : 'n/a'}
          </Field>
        </div>
        <div style={{ padding: '16px', borderRadius: '8px', background: '#F9F9F9', border: '1px solid #E9E9E9' }}>
          <h3 style={{ display: 'flex', justifyContent: 'space-between', margin: '0px', paddingBottom: '8px', marginBottom: '16px', borderBottom: '1px solid #E9E9E9' }}><span>Tarifs</span><small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdatePrices classePackage={classePackage} onUpdated={classePackage => onUpdated(classePackage)} />)}>Modifier</small></h3>
          <Field label="Tarif Institutions">
            {classePackage.fullPriceTtc || 'n/a '}€
          </Field>
          <Field label="Tarif individuel">
            {classePackage.individualPriceTtc || 'n/a '}€
          </Field>
          <Field label="Tarif jeune - 30 ans">
            {classePackage.youthPriceTtc || 'n/a '}€
          </Field>
        </div>
      </div>
      <div style={{ width: '350px' }}>
        <h3 style={{ margin: '0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Cours
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() => modal.open(<SelectClasseModal onSelected={(classe) => onClasseSelected(classe)} excludeIds={classePackage.classes.map(p => p.classe.id)} />)} style={{ width: '12px', height: '12px', fill: '#520b4e', cursor: 'pointer' }} height="426.66667pt" viewBox="0 0 426.66667 426.66667" width="426.66667pt"><path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0"/></svg>
        </h3>
        {
          classePackage.classes.map(({ classe }) => (
            <div key={classe.id} style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Link to="classe" params={{ id: classe.id }}>{classe.number} • {classe.name}</Link>
              <svg style={{ width: '12px', cursor: 'pointer' }} onClick={() => onClasseRemove(classe)} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
            </div>
          ))
        }
        { classePackage.classes.length === 0 &&
          <div style={{ color: 'grey', textAlign: 'center', marginTop: '10px', marginBottom: '20px' }}>
            Aucun
          </div>
        }
      </div>
    </div>
  );
}
