import React, { useState } from 'react';
import { queryToUrl, useRouter } from '../../hooks/router';

import { Link } from '../../hooks/router';
import { Input, Select } from '../../hooks/form';

import { Head } from '../../components/head';
import { List, useList } from '../../hooks/list';
import Api from '../../services/Api';
import { useAuth } from '../../hooks/auth/Auth';
import { useModal } from '../../hooks/modal/Modal';
import { Alert } from '../../components/alert';
import { useNotification } from '../../hooks/notification/Notification';

function StudentFusion() {
  const notification = useNotification();
  const router = useRouter();
  const modal = useModal();
  const [baseStudent, setBaseStudent] = useState(null);
  const [addStudent, setAddStudent] = useState(null);

  function onFusion() {
    Api.post(`/fusion-students`, {
      baseStudentId: baseStudent.id,
      addStudentId: addStudent.id,
    })
      .then(response => {
        notification.notify('Les deux comptes ont été fusionné !');
        router.goTo('student', { id: baseStudent.id });
        modal.close();
      }).catch(error => {
        console.log('ERROR', error);
        alert('An error occured');
      })
  }

  return (
    <div className="list-page" style={{ flex: 1 }}>
      <Head sub noTabs title="Fusion de compte étudiants" />
      <div>
        <form className="form" style={{ padding: '20px' }}>
          { addStudent && baseStudent && baseStudent.id === addStudent.id &&
            <Alert type="red">
              Vous ne pouvez fusioner un étudiant à lui même
            </Alert>
          }
          <Select
            label="Étudiant de référence"
            load={async ({ page, search }) => {
              const response = await Api.get('/students' , { page, search });
              return { items: response.data };
            }}
            value={baseStudent}
            onChange={student => setBaseStudent(student)}
            noSelectionMessage="Aucun"
          >
              {(student, onSelect) => (
                <div key={student.id} onClick={onSelect}>
                  {student.user.firstname} {student.user.lastname.toUpperCase()}
                </div>
              )}
          </Select>
          <br />
          <Select
            label="Étudiant à fusionner"
            load={async ({ page, search }) => {
              const response = await Api.get('/students' , { page, search });
              return { items: response.data };
            }}
            value={addStudent}
            onChange={student => setAddStudent(student)}
            noSelectionMessage="Aucun"
          >
              {(student, onSelect) => (
                <div key={student.id} onClick={onSelect}>
                  {student.user.firstname} {student.user.lastname.toUpperCase()}
                </div>
              )}
          </Select>
        </form>
      </div>
      <div style={{ fontSize: '0.9em', padding: '20px', borderTop: '1px solid #ccc' }}>
        <div>
          • Les informations personnelles seront prise du premier étudiant de référence (email, mot de passe, prénom, ...)
        </div>
        <div>
          • Les inscriptions, documents, factures seront fusionnées
        </div>
      </div>
      <div style={{ padding: '20px', display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid #ccc', borderRadius: '0px 0px 8px 8px' }}>
        <button className="btn" onClick={() => modal.close()} style={{ marginRight: '20px' }}>Annuler</button>
        <button className="btn" disabled={baseStudent === null || addStudent === null || baseStudent.id === addStudent.id} onClick={() => onFusion()}>Fusioner</button>
      </div>
    </div>
  );
}

export default function StudentsList() {
  const modal = useModal();
  const auth = useAuth();
  const { filters, items, isLoading, load } = useList('students', {
    search: '',
    program: null,
  });

  return (
    <div className="list-page">
      <Head title="Étudiants">
        <Link to="createStudent" className="btn">Nouveau</Link>
        <button className="btn" onClick={() => modal.open(<StudentFusion />)}>Fusion</button>
      </Head>
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px' }}>
        <div className="filter full">
          <Input {...filters.field('search')} placeholder="Rechercher" />
        </div>
        <div className="filter" style={{ minWidth: '220px' }}>
          <Select noSearch {...filters.field('program')}>
            <option value={null}>Tous les départements</option>
            <option value="public">Cours Publics</option>
            <option value="e-learning">E-learning</option>
            <option value="fnd">FND</option>
            <option value="issr">ISSR</option>
            <option value="fr">FEB</option>
            <option value="ccdej">CCDEJ</option>
            <option value="fcf">FCF</option>
            <option value="ihefr">IHEFR</option>
            <option value="other">Autre</option>
          </Select>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Étudiant', sort: 'lastname' }, { name: 'Email' }, { name: 'Civilité' }, { name: 'Téléphone' }]}>
        { items.map(student => (
          <Link key={student.id} className="item" to="student" params={{ id: student.id }}>
            <div className="prop" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px', borderRadius: '10px', background: 'green', width: '10px', height: '10px', flex: '0 0 auto' }}></div>
              <span>
                {student.user.lastname.toUpperCase()} {student.user.firstname}
              </span>
            </div>
            <div className="prop">
              {student.user.email}
            </div>
            <div className="prop">
              {student.civility ? {
                'mr': 'M.',
                'mrs': 'Mme',
                'father': 'Père',
                'sister': 'Soeur',
                'brother': 'Frère',
                'mgr': 'Mgr',
              }[student.civility] : 'n/a'}
            </div>
            <div className="prop">
              {student.mobilePhoneNumber}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};