import React from 'react';
import moment from 'moment';

import { Link } from '../../../hooks/router';

import { List, useList } from '../../../hooks/list';

export default function ProductRessourceProducts({ student }) {
  const { items, isLoading, load } = useList(`students/${student.id}/products`, {
    search: '',
  });

  return (
    <div className="list-page">
      <List onLoadMore={load} isLoading={isLoading} headers={[{ name: 'Date d\'achat' }, { name: 'Produit' }, { name: 'Département bénéficiaire' }, { name: 'Type' }]}>
        { items.map(product => (
          <Link key={product.id} className="item" to="product" params={{ id: product.id }}>
            <div className="prop">
              {moment(product.bills[0].createdAt).format('DD/MM/YYYY HH:mm')}
            </div>
            <div className="prop">
              {product.name}
            </div>
            <div className="prop">
              {product.bills[0]?.department ? ({
                'cp': 'Cours Publics',
                'fnd': 'FND',
                'issr': 'ISSR',
                'fr': 'FEB',
                'ccdej': 'CCDEJ',
                'fcf': 'FCF',
                'ihefr': 'IHEFR',
                'e-learning': 'ELEARNING',
                'other': 'Autre',
              }[product.bills[0].department]) :  (<div>
                Aucun
              </div>)}
            </div>
            <div className="prop">
              {{ meal: 'Repas', audio: 'Cours audio', certificate: 'Attestation', other: 'Autre' }[product.type]}
            </div>
          </Link>
        ))}
      </List>
    </div>
  );
};