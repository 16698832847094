import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import { Checkbox, DateRangePicker, Input, Select } from '../../../hooks/form';

import { List, useList } from '../../../hooks/list';
import Api from '../../../services/Api';
import { useModal } from '../../../hooks/modal/Modal';
import BillModal from '../../bills/Ressource';

export default function BillsList({ payer }) {
  const modal = useModal();
  const { filters, items, isLoading, load, selection } = useList('bills', {
    payer: payer.id,
    search: '',
    status: null,
    type: null,
    createdAtFrom: null,
    createdAtTo: null,
    department: null,
  });

  return (
    <div className="list-page">
      <form className="filters" onSubmit={filters.onSubmit} style={{ margin: '20px', marginBottom: '0px', flexDirection: 'column' }}>
        <div className="sub-filters" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="filter full">
            <Input {...filters.field('search') } placeholder="Rechercher" />
          </div>
          <div className="filter" style={{ minWidth: '220px' }}>
            <Select noSearch {...filters.field('department')}>
              <option value={null}>Tous les départements bénéficiaires</option>
              <option value="cp">Cours Publics</option>
              <option value="e-learning">E-learning</option>
              <option value="fnd">FND</option>
              <option value="issr">ISSR</option>
              <option value="fr">FEB</option>
              <option value="ccdej">CCDEJ</option>
              <option value="fcf">FCF</option>
              <option value="ihefr">IHEFR</option>
              <option value="certificate">MOOC</option>
              <option value="meal">Repas</option>
              <option value="other">Autre</option>
            </Select>
          </div>
          <div className="filter" style={{ minWidth: '120px' }}>
            <Select noSearch {...filters.field('status')}>
              <option value={null}>Tous statuts</option>
              <option value="pending">En attente</option>
              <option value="paid">Payée</option>
            </Select>
          </div>
          <div className="filter" style={{ minWidth: '120px' }}>
            <Select noSearch {...filters.field('type')}>
              <option value={null}>Tous types</option>
              <option value="bill">Facture</option>
              <option value="credit">Avoir</option>
            </Select>
          </div>
        </div>
        <div className="sub-filters" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <div className="filter full" style={{ minWidth: '160px' }}>
            <Select
              nullable
              load={async ({ page, search }) => {
                const response = await Api.get('/students' , { page, search });
                return { items: response.data };
              }}
              {...filters.field('student')}
              noSelectionMessage="Tous les étudiants"
            >
                {(student, onSelect) => (
                  <div key={student.id} onClick={onSelect}>
                    {student.user.firstname} {student.user.lastname.toUpperCase()}
                  </div>
                )}
            </Select>
          </div>
          <div className="filter full" style={{ minWidth: '160px' }}>
            <DateRangePicker {...filters.field(['createdAtFrom', 'createdAtTo'])} />
          </div>
        </div>
      </form>
      <List onLoadMore={load} isLoading={isLoading} headers={[
        { select: 'checkbox', selection, items },
        { name: 'Créée le', sort: 'createdAt' },
        { name: 'Numéro', sort: 'number' },
        { name: 'Type', sort: 'type' },
        { name: 'Montant' },
        { name: 'Montant payé' },
        { name: 'Mode de paiement' },
        { name: 'Nom de l\'inscrit' },
        { name: 'Numéro de cours/produit' }
      ]}>
        { items.map(bill => (
          <div key={bill.id} className="item" onClick={() => modal.open(<BillModal billId={bill.id} billNumber={bill.number} billType={bill.type} />)}>
            <div className="checkbox">
              <Checkbox checked={selection.isSelected(bill)} onChange={() => selection.select(bill)} inline />
            </div>
            <div className="prop">
                {moment(bill.createdAt).format('DD/MM/YYYY')}
            </div>
            <div className="prop">
              #{bill.number}
            </div>
            <div className="prop">
              {{
                bill: 'Facture',
                credit: 'Avoir'
              }[bill.type]}
            </div>
            <div className="prop">
              {bill.amount}€
            </div>
            <div className="prop">
              {bill.amountPaid}€ {bill.threeTime ? '*' : ''}
            </div>
            <div className="prop">
              {_.uniq(bill.payments.map(payment => ({
                web: 'Web',
                bankcard: (payment.externalId && payment.externalId.slice(0, 3) === 'pi_' ? 'Stripe' : 'CB'),
                change: 'Espèces',
                check: 'Chèque',
                'bank-transfer': 'Virement',
              }[payment.paymentMethod]))).join(' • ')}
              { bill.paymentMethod &&
                <>
                  {{
                    web: 'Web',
                    bankcard: 'CB',
                    change: 'Espèces',
                    check: 'Chèque',
                    'bank-transfer': 'Virement',
                    shopify: 'Shopify',
                    stripe: 'Stripe',
                  }[bill.paymentMethod]}
                </>
              }
            </div>
            <div className="prop">
              { bill.registrations && bill.registrations.length > 0 ? (
                <>{(bill.registrations[0].registration.student.user.lastname ? bill.registrations[0].registration.student.user.lastname.toUpperCase() : '')} {bill.registrations[0].registration.student.user.firstname}</>
              ) : (
                <>n/a</>
              )}
            </div>
            <div className="prop">
              { bill.registrations && bill.registrations.length > 0 ? (
                <>{bill.registrations.filter(registration => registration.registration.classe).map(registration => `${registration.registration.classe.number || registration.registration.classe.productCode}`)}</>
              ) : (
                <></>
              )}
              { bill.products && bill.products.length > 0 ? (
                <>{bill.products.map(product => `${product && product.product ? product.product.code : ''}`)}</>
              ) : (
                <></>
              )}
            </div>
          </div>
        ))}
      </List>
    </div>
  );
};